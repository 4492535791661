<template>
  <div class="container login-register-container">
    <img src="@/assets/image/login-register-bg.png" alt="" class="bg" />
    <div class="box-view">
      <!-- <div class="box-title">
        <img
          src="@/assets/image/cqc-login-register-title.png"
          alt=""
          class="title"
        />
      </div> -->
      <div class="box-title">
        <router-link to="/">
          <img src="@/assets/image/cqc-login-register-title.png" alt="" class="title" />
        </router-link>
      </div>
      <div class="box-link">
        <div class="forget-pass">忘记密码</div>
        <el-form ref="form" label-width="0px" :model="form" :rules="rules">
          <el-form-item prop="userName">
            <el-input v-model="form.userName" placeholder="请输入手机号码" />
          </el-form-item>
          <!-- 验证码 -->
          <el-form-item class="yzm-box" prop="code">
            <!-- <el-input
          type="password"
          v-model="form.code"
          placeholder="请输入验证码"
        />
        <div class="yzm">获取验证码</div> -->
            <!-- 请输入验证码 -->
            <div class="verififcation">
              <el-input type="text" v-model="form.code" placeholder="请输入验证码" />
              <!-- <input type="text" placeholder="请输入验证码" v-model="form.code" /> -->
              <input
                v-show="!epmty"
                type="button"
                :disabled="!send"
                class="yzm"
                :value="codetext"
                @click="seedCode"
                v-bind="teSting"
              />
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="form.password" placeholder="请输入密码" />
          </el-form-item>
          <el-form-item prop="again">
            <el-input type="password" v-model="form.again" placeholder="请确认密码" />
          </el-form-item>
          <!-- 平台协议 -->

          <el-form-item>
            <el-button type="primary" @click="submitForm">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="link-view">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { forgetPassword, verifyCode } from "@/api/login";
import { getToken } from "@/utils/auth";
import { Message } from "element-ui";
export default {
  name: "register",
  data() {
    let checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };

    let checkPassword = (rule, value, callback) => {
      if (
        !/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*/.]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*/.]+$)(?![\d!@#$%^&*/.]+$)[a-zA-Z\d!@#$%^&*/.]+$/.test(
          value
        )
      ) {
        callback(new Error("密码必须包含字母数字和特殊字符"));
      } else {
        callback();
      }
    };
    let checkAgain = (rule, value, callback) => {
      if (value != this.form.password) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };

    return {
      radio: "",
      dialogVisible: false,
      show: false,
      iphone: "",
      code: "",
      password: "",
      isregist: false,
      //输入空为空时
      sendregis: true,
      //必填的填写完毕
      regisin: false,
      codetext: "获取验证码",
      send: true,
      //验证码样式
      sendcode: true,
      codein: false,
      funikm: true,
      epmty: true,
      iphones: "",
      queryForm: {
        phone: "17370349207",
        key: "rest-pw",
      },
      form: {
        userName: undefined,
        password: undefined,
        code: undefined,
        again: undefined,
        verification: undefined,
      },
      rules: {
        userName: [{ validator: checkUsername, trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度为6到18位", trigger: "blur" },
          // { validator: checkPassword, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        again: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: checkAgain, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //发送验证码
    seedCode() {
      var that = this;
      //时间
      var countdown = 60;
      codetime();
      function codetime() {
        if (countdown == 0) {
          that.codetext = "获取验证码";
          countdown = 60;
          that.send = true;
          that.codein = false;
          that.sendcode = true;
          return;
        } else {
          countdown--;
          that.codetext = "" + countdown + "秒后重发";
          that.send = false;
          that.codein = true;
          that.sendcode = false;
        }
        setTimeout(function () {
          codetime();
        }, 1000);
      }
      //发送验证码

      verifyCode(this.queryForm).then((res) => {
        console.log("发送验证码成功");
        console.log(res);
      });
      // getcode({
      //   mobile: that.iphone,
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     //手机号码已经存在
      //     that.iphones = error.mobile[0];
      //     // that.showToast();
      //   });
    },
    //手机号码不正确的提示
    epmtyCode() {
      this.iphones = "手机号码不正确";
      // this.showToast();
    },
    //
    handleClose(done) {
      done();
      this.dialogVisible = false;
      this.dialogVisible2 = false;
    },
    // 协议弹框
    dialogVisiblebtn: function () {
      this.dialogVisible = true;
    },
    //
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          forgetPassword(this.form).then(() => {
            Message({
              message: "修改成功",
              type: "success",
            });
            setTimeout(() => {
              window.history.back();
            }, 1000);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  //监听
  computed: {
    //验证手机号码是否正确
    teSting() {
      if (!/^1(3|4|5|7|8|9)\d{9}$/.test(this.form.userName)) {
        console.log("手机号码不正确");
        this.send = false;
        this.epmty = false;
      } else {
        console.log("手机号码正确");
        this.send = true;
        this.epmty = false;
        this.queryForm.phone = this.form.userName;
      }
    },
  },

  created() {
    if (getToken() != null) {
      this.$router.push({ path: "/" });
    }
  },
};
</script>

<style lang="scss">
.login-register-container {
  position: relative;
  width: 100vw;
  height: 100vh;

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .box-view {
    position: absolute;
    width: 460px;
    height: 500px;
    top: 50%;
    left: 50%;
    margin-top: -260px;
    margin-left: -200px;
    background: #ffffff;
    box-shadow: 0 0 20px #999999;
    border-radius: 20px;
    .yzm-box {
      position: relative;
      .yzm {
        position: absolute;
        top: -14px;
        right: 0px;
        width: 119px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        background: #1d70ea;
        border-radius: 6px;
        color: #fff;
        font-size: 16px;
        border: 0;
        outline: 0;
      }
    }
    ::v-deep .el-radio__input {
      cursor: pointer;
      vertical-align: middle;
      margin-left: 50px;
    }
    .xieyi-box-1 {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1d70ea;
      margin-left: 0px;
      cursor: pointer;
    }
    .dialog-title {
      color: #000;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .dialog-content {
      line-height: 26px;
    }
    .box-title {
      position: absolute;
      width: 200px;
      height: 64px;
      top: -32px;
      left: 50%;
      margin-left: -100px;
      background: #3e7eff;
      border-radius: 25px;
      box-shadow: 0 0 10px #999;
      line-height: 64px;

      .title {
        width: 80%;
        height: 50%;
        position: absolute;
        top: 25%;
        left: 10%;
      }
    }

    .box-link {
      width: 80%;
      height: 60px;
      line-height: 60px;
      margin: 0 auto;
      margin-top: 40px;
      .forget-pass {
        text-align: center;
        font-size: 20px;
      }
      .link-btn-box {
        float: left;
        width: 50%;
        text-align: center;

        .link-btn {
          cursor: pointer;
          font-size: 24px;
          color: #666666;
        }

        .active {
          color: #3e7eff;
        }
      }
    }

    .link-view {
      width: 100%;
      height: 300px;
      padding: 20px 60px 20px 60px;
    }
  }

  .el-input,
  .el-input__inner {
    border-radius: 0;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .el-input__inner {
    border-bottom: 1px solid #cccccc;
  }
  .el-button {
    width: 100%;
    background: #3e7eff;
    span {
      font-size: 18px;
    }
  }
}
</style>
